import * as React from "react"
const Ig = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={36}
    height={36}
    fill="none"
    {...props}
  >
    <path
      fill="#fff"
      d="M18 2.472c8.567 0 15.543 6.977 15.543 15.544S26.567 33.559 18 33.559 2.457 26.583 2.457 18.016C2.457 9.433 9.433 2.472 18 2.472ZM18 0C8.063 0 0 8.063 0 18s8.063 18 18 18 18-8.063 18-18S27.937 0 18 0Z"
    />
    <path
      fill="#fff"
      d="M27.575 13.291c-.126-1.26-.614-2.362-1.543-3.26-.977-.928-2.19-1.354-3.496-1.417-2.237-.126-7.56-.204-9.433.11-2.142.347-3.544 1.591-4.126 3.701-.457 1.622-.363 9.103-.095 10.709.362 2.189 1.654 3.606 3.827 4.157 1.496.394 8.898.347 10.551.064 2.19-.347 3.606-1.638 4.173-3.811.441-1.67.284-8.756.142-10.253Zm-1.764 9.528c-.252 1.67-1.354 2.693-3.04 2.882-1.542.173-8.409.268-9.826-.142-1.37-.378-2.142-1.323-2.378-2.693-.22-1.307-.268-7.874 0-9.622.252-1.653 1.354-2.677 3.024-2.866 1.685-.189 7.764-.205 9.386.032 1.669.252 2.692 1.37 2.881 3.039.174 1.575.221 7.685-.047 9.37Zm-7.622-9.67a4.89 4.89 0 0 0-4.897 4.883c0 2.693 2.188 4.897 4.882 4.897 2.692 0 4.897-2.189 4.897-4.882a4.87 4.87 0 0 0-4.882-4.897Zm-.031 8.048a3.166 3.166 0 1 1 .063-6.33 3.166 3.166 0 0 1-.063 6.33Zm6.267-8.236c0 .63-.52 1.15-1.15 1.15-.63 0-1.149-.52-1.149-1.15 0-.63.52-1.15 1.15-1.134.63 0 1.15.504 1.15 1.134Z"
    />
    <path
      fill="#fff"
      d="M27.575 13.291c-.126-1.26-.614-2.362-1.543-3.26-.977-.928-2.19-1.354-3.496-1.417-2.237-.126-7.56-.204-9.433.11-2.142.347-3.544 1.591-4.126 3.701-.457 1.622-.363 9.103-.095 10.709.362 2.189 1.654 3.606 3.827 4.157 1.496.394 8.898.347 10.551.064 2.19-.347 3.606-1.638 4.173-3.811.441-1.67.284-8.756.142-10.253Zm-1.764 9.528c-.252 1.67-1.354 2.693-3.04 2.882-1.542.173-8.409.268-9.826-.142-1.37-.378-2.142-1.323-2.378-2.693-.22-1.307-.268-7.874 0-9.622.252-1.653 1.354-2.677 3.024-2.866 1.685-.189 7.764-.205 9.386.032 1.669.252 2.692 1.37 2.881 3.039.174 1.575.221 7.685-.047 9.37Zm-7.622-9.67a4.89 4.89 0 0 0-4.897 4.883c0 2.693 2.188 4.897 4.882 4.897 2.692 0 4.897-2.189 4.897-4.882a4.87 4.87 0 0 0-4.882-4.897Zm-.031 8.048a3.166 3.166 0 1 1 .063-6.33 3.166 3.166 0 0 1-.063 6.33Zm6.267-8.236c0 .63-.52 1.15-1.15 1.15-.63 0-1.149-.52-1.149-1.15 0-.63.52-1.15 1.15-1.134.63 0 1.15.504 1.15 1.134Z"
    />
  </svg>
)
export default Ig
