import * as React from "react"
const Fb = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={36}
    height={36}
    fill="none"
    {...props}
  >
    <path
      fill="#fff"
      d="M18 2.472c8.567 0 15.543 6.977 15.543 15.544S26.567 33.559 18 33.559 2.457 26.583 2.457 18.016C2.457 9.433 9.433 2.472 18 2.472ZM18 0C8.063 0 0 8.063 0 18s8.063 18 18 18 18-8.063 18-18S27.937 0 18 0Z"
    />
    <path
      fill="#fff"
      fillRule="evenodd"
      d="M20.315 11.322c.661-.016 1.307 0 1.968 0h.268V7.905c-.347-.032-.709-.079-1.087-.11-.677-.032-1.338-.063-2.015-.048-1.024.016-2 .268-2.85.882-.962.709-1.497 1.685-1.702 2.85-.078.473-.11.977-.126 1.465-.015.772 0 1.528 0 2.3v.283h-3.26v3.81h3.245v9.56h3.952v-9.543h3.229c.173-1.26.33-2.52.504-3.827h-3.748s.015-1.89.031-2.709c.032-1.134.693-1.464 1.59-1.496Z"
      clipRule="evenodd"
    />
  </svg>
)
export default Fb
