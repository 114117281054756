import * as React from "react"
const In = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={36}
    height={36}
    fill="none"
    {...props}
  >
    <path
      fill="#fff"
      d="M18 2.472c8.567 0 15.543 6.977 15.543 15.544S26.567 33.559 18 33.559 2.457 26.583 2.457 18.016C2.457 9.433 9.433 2.472 18 2.472ZM18 0C8.063 0 0 8.063 0 18s8.063 18 18 18 18-8.063 18-18S27.937 0 18 0Z"
    />
    <path
      fill="#fff"
      d="M10.3 14.237h3.653v11.732h-3.654V14.237Zm1.826-5.827a2.11 2.11 0 0 1 2.11 2.11 2.124 2.124 0 0 1-2.11 2.126A2.127 2.127 0 0 1 10 10.52a2.134 2.134 0 0 1 2.126-2.11ZM16.252 14.237h3.496v1.606h.047c.488-.93 1.685-1.89 3.449-1.89 3.7 0 4.378 2.425 4.378 5.59v6.442h-3.654v-5.701c0-1.354-.031-3.118-1.89-3.118-1.905 0-2.188 1.48-2.188 3.008v5.81h-3.638V14.238Z"
    />
  </svg>
)
export default In
